import { authRoles } from 'app/auth'; //* PLS - 15/05/2019 - Add Authentification
import i18next from 'i18next';

import en from './navigation-i18n/en';
import fr from './navigation-i18n/fr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('fr', 'navigation', fr);

const navigationConfig = [
  {
    id: 'applications',
    title: 'Applications',
    translate: 'APPLICATIONS',
    type: 'group',
    icon: 'apps',
    // auth   : authRoles.onlyGuest,
    children: [
      {
        id: 'dashboards',
        title: 'Tableau de Bord',
        type: 'item',
        translate: 'DASHBOARDS',
        icon: 'dashboard',
        auth: authRoles.dashboard,
        url: '/dashboard',
      },
      {
        id: 'agenda',
        title: 'Agenda',
        type: 'item',
        icon: 'today',
        translate: 'AGENDA',
        auth: authRoles.agenda,
        url: '/agenda',
      },
      {
        id: 'patients',
        title: 'Dossiers patients',
        type: 'item',
        icon: 'folder_shared',
        translate: 'PATIENTS',
        auth: authRoles.dps,
        url: '/patients',
      },
      {
        id: 'appointment',
        title: 'Appointment',
        translate: 'APPOINTMENT',
        type: 'item',
        icon: 'date_range',
        auth: authRoles.appointment,
        url: '/appointment',
      },
      {
        id: 'messages',
        title: 'Messageries',
        type: 'item',
        icon: 'chat',
        translate: 'MESSAGEPATIENTS',
        auth: authRoles.developer,
        url: '/messaging',
      },
      {
        id: 'notes',
        title: 'Notes',
        translate: 'Notes',
        type: 'item',
        icon: 'whatshot',
        auth: authRoles.note,
        url: '/notes',
      },
			{
				id: 'dietetiques',
				title: 'Dietetiques',
				translate: 'DIETETIQUE',
				type: 'item',
				icon: 'whatshot',
				auth: authRoles.developer,
				url: '/dietetiques'
			},
      {
        id: 'statistics',
        title: 'Statistiques',
        type: 'item',
        icon: 'assessment',
        url: '/pages/maintenance',  // url: '/statistics',
        auth: authRoles.developer,
      },
      {
        id: 'users',
        title: 'Utilisateurs',
        type: 'item',
        icon: 'admin_panel_settings',
        translate: 'USERS',
        auth: authRoles.user,
        url: '/user',
      },
      {
        id: 'organizations',
        title: 'Organisations',
        type: 'item',
        icon: 'room_preferences',
        translate: 'Organisations',
        auth: authRoles.organisation,
        url: '/organizations',
      }, 
      {
        id: 'admin',
        title: 'Administration WellBe',
        type: 'item',
        icon: 'settings_applications',
        translate: 'WELLBESETTING',
        auth: authRoles.developer,
        url: '/admin',
      },
    ],
  },
  {
    id: 'example-component',
    title: 'Example',
    translate: 'EXAMPLE',
    type: 'item',
    icon: 'whatshot',
    auth: authRoles.developer,
    url: '/example',
  },
];

export default navigationConfig;
