import { useEffect } from 'react';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import _ from '@lodash';
import { Auth, authRoles } from './auth';
import withAppProviders from './withAppProviders';
import ConsultationBar from './shared-components/ConsultationBar';
// import ChatPanel from './shared-components/chatPanel/ChatPanel';
// import { selectChatPanelState } from './shared-components/chatPanel/store/stateSlice';

const App = () => {
  // const login = useSelector(({ auth }) => auth.login);
  // const user = useSelector(({ auth }) => auth.user);
  // const userRole = useSelector(({ auth }) => auth.user.role);
  // const state = useSelector(selectChatPanelState);

  // useEffect(() => {}, [state]);

  return (
    <Auth>
      <Router history={history}>
        <FuseAuthorization>
          <FuseTheme>
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              classes={{
                containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99 overflow-scroll',
              }}
            >
              <FuseLayout />
              {/* {(login && login.success && _.includes(authRoles.chats, userRole)) ||
              (user && user.token && _.includes(authRoles.chats, userRole)) ? (
                <ChatPanel />
              ) : null} */}
              <ConsultationBar />
            </SnackbarProvider>
          </FuseTheme>
        </FuseAuthorization>
      </Router>
    </Auth>
  );
};

export default withAppProviders(App)();
