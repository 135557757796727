/* eslint-disable no-restricted-syntax */
import React, { useRef, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  // RemoveFileIcon,
  InputLabel,
} from "./uploadDragDrop.styles";
import { FileUploader } from "react-drag-drop-files";
import { Box } from "@material-ui/core";

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 50000000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const Input = (props) => (
  <input
    type="file"
    accept="image/*"
    name="img-loader-input"
    multiple
    {...props}
  />
);

const UploadDragDrop = ({
  labelInput,
  labelPreview,
  onChange,
  margin = "margin: 25px 0 15px",
  uploadFiles,
  disaModif,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});

  React.useEffect(() => {
    if (_.isEmpty(uploadFiles) && !_.isEmpty(files)) {
      setFiles({});
    }
  }, [uploadFiles, files]);

  const handleUploadBtnClick = (e) => {
    e.stopPropagation();
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (const file of newFiles) {
      if (file.size < maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  // eslint-disable-next-line no-shadow
  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    onChange(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      const updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  const handleDropFiles = (newFiles) => {
    if (!disaModif) {
      callUpdateFilesCb([newFiles]);
      setFiles(newFiles);
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Box
    component="button"
    disabled={disaModif}
      onClick={(e) => handleClick(e)}
      style={{
        cursor: "pointer",
      }}
    >
      <FileUploader
        handleChange={(dropedFile) => handleDropFiles(dropedFile)}
        name="file"
        multiple={otherProps.multiple}
        disabled={otherProps.disaModif} 
        style={{
          cursor: "pointer",
          margin: "25px 0",
        }}
      >
        <FileUploadContainer margin={margin}>
          <InputLabel
            sx={{
              my: 10,
            }}
          >
            {labelInput}
          </InputLabel>
          <DragDropText
            sx={{
              my: 10,
            }}
          >
            Faites glisser et déposez vos fichiers ici
          </DragDropText>
          <UploadFileBtn
            multiple={otherProps.multiple}
            type="button"
            onClick={(e) => handleUploadBtnClick}
            disabled={disaModif}
          >
            <i className="fa-solid fa-file-arrow-up" />
            <span>
              Ajouter {otherProps.multiple ? "des fichiers" : "un fichier"}
            </span>
          </UploadFileBtn>
          <FormField
            type="file"
            ref={fileInputField}
            onChange={handleNewFileUpload}
            disabled={disaModif}
            title=""
            value=""
            {...otherProps}
          />
        </FileUploadContainer>
        <FilePreviewContainer marginBottom="0px">
          <span>{labelPreview}</span>
          <PreviewList>
            {files &&
              files[0] &&
              files[0].name &&
              Object.keys(files).map((fileName, index) => {
                const file = files[fileName];
                const isImageFile = file.type.split("/")[0] === "image";
                return (
                  <PreviewContainer key={fileName}>
                    <div>
                      {isImageFile && (
                        <ImagePreview
                          src={URL.createObjectURL(file)}
                          alt={`file preview ${index}`}
                        />
                      )}
                      <FileMetaData isImageFile={isImageFile}>
                        <span>{file.name}</span>
                        <aside>
                          <span>{convertBytesToKB(file.size)} kb</span>
                          <DeleteIcon
                            // className="fas fa-trash-alt"
                            onClick={() => removeFile(fileName)}
                          />
                        </aside>
                      </FileMetaData>
                    </div>
                  </PreviewContainer>
                );
              })}
          </PreviewList>
        </FilePreviewContainer>
      </FileUploader>
    </Box>
  );
};

export default UploadDragDrop;
